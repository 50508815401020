<template>
  <div>
    <div class="text-right m-b-10">
      <Button type="success" size="small" @click="handleMeterialManage">新素材上传</Button>
    </div>
    <div>
      <Divider dashed style="color: #fff">已有素材管理</Divider>
      <Row class="p-b-10">
        <i-col span="5">
          <label>素材类型：</label>
        </i-col>
        <i-col span="19">
          <RadioGroup v-model="screenType" type="button" size="small" @on-change="onChangeType">
            <Radio v-for="item in screenTypes" :key="'stype_' + item.value" :label="item.value">{{ item.name }}</Radio>
          </RadioGroup>
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="5">
          <label>素材状态：</label>
        </i-col>
        <i-col span="19">
          <RadioGroup size="small" v-model="screenStatus" type="button">
            <Radio v-for="item in screenStatuss" :key="item.id" :label="item.id">{{ item.name }}</Radio>
          </RadioGroup>
        </i-col>
      </Row>
      <Row class="p-b-10">
        <i-col span="5">
          <label>素材库：</label>
        </i-col>
        <i-col span="19">
          <Select v-model="query.materialLibrary" size="small" clearable filterable placeholder="选择素材库，可搜索" @on-change="onChangeMaterialLib">
            <Option v-for="item in materialLibList" :key="item" :value="item">{{ item }}
            </Option>
          </Select>
        </i-col>
      </Row>
      <i-table stripe :data="existsFile" :columns="fileColumns"></i-table>
      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" show-total :current="query.pageNumber"
          @on-change="handlePageNumberChange"></Page>
      </div>
    </div>
    <!-- 上刊材料预览（画面、视频） -->
    <Modal v-model="visibleImage" width="800" :footer-hide="true">
      <p slot="header">{{ fileName }}</p>
      <p v-if="approvaldesc">审批意见：{{ approvaldesc }}</p>
      <img v-if="fileType === 1" :src="fileUrl + '?x-oss-process=image/resize,w_968'" style="width: 100%" />
      <video v-else id="videoShow" :src="fileUrl" controls="controls" style="width:100%">
        您的浏览器不支持预览视频。
      </video>
    </Modal>
    <Modal v-model="changeTypeModal" width="400" @on-ok="handleChangeType">
      <p class="text-center" slot="header">变更素材类型</p>
      <div>
        <Select v-model="curMaterialType" size="small" placeholder="素材类型">
          <Option v-for="item in materialTypes" :key="'mtype_' + item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
      </div>
    </Modal>
    <Modal v-model="materialLibModal" width="400" @on-ok="confirmChangeMaterialLib">
      <p class="text-center" slot="header">变更素材库</p>
      <div>
        <div class="m-b-10">提示：选择需要变更的素材库，若没有找到合适的素材库，请选择“添加素材库”，填写素材库名称后直接提交。</div>
        <Row :gutter="8" class="m-b-10">
          <i-col span="16">
            <Select v-model="materialLibrary" :disabled="newAddMaterialLib" size="small" clearable filterable placeholder="选择素材库，可搜索">
              <Option v-for="item in materialLibList" :key="'mLib_' + item" :value="item">{{ item }}</Option>
            </Select>
          </i-col>
          <i-col span="8">
            <Button type="primary" size="small" :icon="newAddMaterialLib ? 'md-remove' : 'md-add'"
              @click="onAddMaterialLib">{{ newAddMaterialLib ? '取消添加' : '添加素材库' }}</Button>
          </i-col>
        </Row>
        <div v-show="newAddMaterialLib">
          <Input v-model="newMaterialLib" size="small" placeholder="请输入素材库名称" />
        </div>

      </div>
    </Modal>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { taskMixins } from '../mixins/index'
import { agreefile, deletefile, updateFileType, modifyMaterialLibrary } from '@/api/msp/file'
import { getFillType } from '@/api/msp/status'
import { downloadFileRequest } from '@/utils/download'
export default {
  mixins: [sysMixins, taskMixins],
  data () {
    return {
      total: 0,
      screenTypes: [
        { value: 0, name: '全部' }
      ],
      screenType: 0,
      screenStatuss: [
        { id: 0, name: '全部' },
        { id: 4, name: '审核中' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' }
      ],
      screenStatus: 0,
      fileType: 1,
      visibleImage: false,
      qMaterialLibrary: '', // 搜索素材库名称
      fileUrl: '',
      fileName: '',
      approvaldesc: '',

      existsFile: [],
      fileColumns: [
        {
          title: '文件',
          render: (h, data) => {
            const html = []
            if (data.row.mimeType.startsWith('image')) {
              html.push(h('img', {
                domProps: {
                  src: data.row.fileKey + '?x-oss-process=image/resize,w_64',
                  title: '预览大图'
                },
                style: { cursor: 'pointer' },
                on: {
                  click: () => {
                    this.handlePreview(data.row)
                  }
                }
              }))
            } else if (data.row.mimeType.startsWith('video')) {
              html.push(h('Icon', {
                domProps: {
                  title: '预览视频'
                },
                props: { type: 'md-videocam', size: 32, color: '#e96900' },
                style: { 'margin-left': '8px', cursor: 'pointer' },
                on: {
                  click: () => {
                    this.handlePreview(data.row)
                  }
                }
              }))
            }
            html.push(h('p', data.row.fileName))
            return html
          }
        },
        { title: '素材库', align: 'center', key: 'materialLibrary' },
        // { title: '文件名称', key: 'fileName' },
        // { title: '文件大小 (kb)', key: 'length' },
        { title: '状态', align: 'center', key: 'statusName' },
        {
          title: '操作',
          width: 90,
          align: 'center',
          render: (h, data) => {
            const html = [
              h('a', {
                style: { display: 'block' },
                on: {
                  click: () => {
                    this.curFileId = data.row.id
                    this.materialLibrary = ''
                    this.newAddMaterialLib = false
                    this.materialLibModal = true
                  }
                }
              }, '变更素材库')
            ]
            // if (data.row.mimeType.startsWith('image') || data.row.mimeType.startsWith('video')) {
            //   preview = h(
            //     'a',
            //     {
            //       style: { 'margin-right': '5px' },
            //       on: {
            //         click: () => {
            //           if (data.row.type === 1) { this.handlePreview(data.row) } else if (data.row.type === 3) { this.handleVideoPreview(data.row) }
            //         }
            //       }
            //     },
            //     '预览'
            //   )
            // }
            if ([5, 6].includes(data.row.status)) {
              html.push(
                h('a', {
                  style: { display: 'block' },
                  on: {
                    click: () => {
                      this.curFileId = data.row.id
                      this.curMaterialType = data.row.fileType
                      this.changeTypeModal = true
                    }
                  }
                }, '变更类型'),
                h(
                  'a',
                  {
                    style: { color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.handleRemove(data.row)
                      }
                    }
                  }, '删除')
              )
            }
            html.push(h(
              'a',
              {
                style: { 'margin-right': '5px', display: 'block' },
                on: {
                  click: () => {
                    this.handleDownload(data.row)
                  }
                }
              },
              '下载'
            ))
            return h('div', html)
          }
        }
      ],
      query: {
        pageSize: 10,
        pageNumber: 1,
        materialLibrary: '',
        fileTypes: '1,3'
      },
      materialTypes: [],
      changeTypeModal: false,
      curFileId: null,
      curMaterialType: null,
      // 素材库相关字段
      materialLibrary: '', // 变更时选中素材库
      materialLibModal: false,
      newAddMaterialLib: false,
      newMaterialLib: ''
    }
  },
  created () {
    this.getScreenTypeData()
    this.getExistsFiles()
    this.getMaterialLibData()
  },
  methods: {
    getScreenTypeData () {
      getFillType().then(res => {
        if (res && !res.errcode) {
          this.materialTypes = res
          this.screenTypes = this.screenTypes.concat(res)
        }
      })
    },
    onChangeType () {
      const type = this.screenType === 0 ? null : this.screenType
      this.getMaterialLibData(type)
    },
    onChangeMaterialLib () {
      this.reloadList()
    },
    /**
     * 获取上传的素材
     */
    getExistsFiles () {
      this.existsFile = []
      this.query.status = this.screenStatus ? this.screenStatus : null
      this.query.type = this.screenType === 0 ? null : this.screenType
      this.total = 0
      agreefile(this.query).then((res) => {
        if (res.totalRow && res.list) {
          this.total = res.totalRow
          this.existsFile = res.list
        } else if (res.totalRow && !res.list) {
          this.query.pageNumber = this.query.pageNumber - 1
          this.getExistsFiles()
        }
      })
    },
    formatImageUrl (fileUrl, width, height) {
      const widthstr = width === 0 ? '' : ',w_' + width
      const heightstr = height === 0 ? '' : ',h_' + height

      return (
        fileUrl + '?x-oss-process=image/resize,m_lfit' + widthstr + heightstr
      )
    },
    handlePreview (file) {
      this.fileUrl = file.fileKey
      this.fileName = file.fileName
      this.approvaldesc = file.approvaldesc
      this.fileType = file.type
      this.visibleImage = true
    },
    handleMeterialManage () {
      this.handlePage()
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'MaterialUpload', value: '素材管理', actived: true })
      this.setLinkRouterMaps([
        'MaterialUpload',
        () => {
          this.handlePage()
        }
      ])

      this.setActivedTagName('MaterialUpload')
    },
    handleDownload (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-msp/v2/file/downloadfile', { fileId: file.id }, file.fileName)
    },

    handleRemove (file) {
      this.$Modal.confirm({
        title: '确定删除？',
        content: '<p>确定要删除该素材，删除后将不可恢复！</p>',
        loading: true,
        onOk: () => {
          deletefile({ fileId: file.id }).then(res => {
            this.getExistsFiles()
            this.$Modal.remove()
          })
        }
      })
    },
    /**
     * 切换素材类型
     */
    handleChangeType () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要变更当前素材类型？',
        onOk: () => {
          updateFileType({ fileId: this.curFileId, type: this.curMaterialType }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.getExistsFiles()
            }
          })
        }
      })
    },
    onAddMaterialLib () {
      this.newAddMaterialLib = !this.newAddMaterialLib
      this.newMaterialLib = !this.newAddMaterialLib ? '' : this.newMaterialLib
      this.materialLibrary = this.newAddMaterialLib ? '' : this.materialLibrary
    },
    /**
     * 变更素材库
     */
    confirmChangeMaterialLib () {
      if (this.materialLibrary === '' && this.newMaterialLib.trim() === '') {
        this.$Notice.error({ desc: '请选择素材库' })
        return
      }
      const postData = {
        fileId: this.curFileId,
        materialLibrary: this.newMaterialLib.trim().length ? this.newMaterialLib : this.materialLibrary
      }
      modifyMaterialLibrary(postData).then(res => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.getExistsFiles()
          this.getMaterialLibData()
        }
      })
    },
    handlePage () {
      this.setBottomComponent('MaterialManage')
      this.setLeftComponent()
      this.setRightComponent('MaterialRecord')
      this.setShowRightBlock(true)
      this.setShowLeftBlock(false)
    },
    reloadList () {
      this.query.pageNumber = 1
      this.getExistsFiles()
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.getExistsFiles()
    }
  },
  watch: {
    screenType () {
      this.reloadList()
    },
    screenStatus () {
      this.reloadList()
    },
    visibleImage (val) {
      // 视频预览，关闭弹窗，暂停播放
      if (!val && this.fileType === 3) {
        window.document.getElementById('videoShow').pause()
      }
    }
  }
}
</script>
